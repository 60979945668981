import * as React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPageContainer = styled.div`
  padding-top: 130px;
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundPageContainer>
      <h1>404: Not Found</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </NotFoundPageContainer>
  </Layout>
);

export default NotFoundPage;
